import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <p>{`For general design and usage guidelines of this component, see the `}<a parentName="p" {...{
        "href": "https://primer.style/brand/components/Button#usage"
      }}>{`button component`}</a>{` in our Brand documentation.`}</p>
    <img src="https://github.com/primer/design/assets/586552/1abfae09-27e7-419d-8c69-abb3122f5928" role="presentation" width="960" alt="A marketing button Rails component" />
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="ButtonMarketing" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      